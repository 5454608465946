<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="archive" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{ translate('schedule_associate_trainings_or_exercises') }}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info">
                    <p>{{ translate('do_you_want_to_associate_trainings_or_exercises') }}</p>
                    <b-button :class="assign_entity === 'trainings' ? 'focus active' : ''" class="mr-2" type="button" variant="primary" @click="setAssignEntity('trainings')">
                        <q-icon size="1rem" name="fas fa-running" /> {{ translate('trainings') }}
                    </b-button>
                    <b-button :class="assign_entity === 'exercises' ? 'focus active' : ''" type="button" variant="primary" @click="setAssignEntity('exercises')">
                        <q-icon size="1rem" name="fas fa-running" /> {{ translate('exercises') }}
                    </b-button>
                </b-list-group-item>
                <template v-if="assign_entity !== null">
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info bg-custom-lightblue font-weight-bold">
                        <q-icon size="1rem" name="fas fa-running" /> {{ translate(assign_entity) }}
                    </b-list-group-item>
                    <b-list-group-item class="list-group-item-accent-info">
                        <include-item-form 
                            :placeholder_find_item="placeholder_find_item" 
                            @update_list="updateAssignedItems" 
                            :item_id="item_id" 
                            :post_route="assign_post_route" 
                            :get_route_to_include="get_route_to_include" 
                            :entity_type="assign_entity">
                        </include-item-form>
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold">
                        <q-icon :title="translate('Click to show or hide the included items')" @click.prevent="isHidden = !isHidden" class="cursor-pointer" name="list" />&nbsp;
                        <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                            {{ assigned_entity_title }}
                            <q-badge color="primary" floating>{{ items && items.length ? items.length : '0' }}</q-badge>
                        </strong>
                    </b-list-group-item>
                    <b-list-group-item v-if="!isHidden && items && items.length" class="list-group-item-accent-info" :class="'list_wrapper-items-' + assign_entity + '-' + item_id">
                        <div v-for="(item,i) in items" :key="'included-item-' + i + item.id" :id="item.id">
                            <q-item class="list-item" clickable ripple>
                                <q-item-section>
                                    {{ item[entity + '_name'] }}
                                </q-item-section>
                                <template v-if="!$q.screen.lt.sm">
                                    <q-item-section class="p-0 bg-yellow-2 notes-edit">
                                        <textarea rows="1" class="bg-yellow-2 p-2 full-width" v-model="item.new_notes" :placeholder="translate('notes')"></textarea>
                                    </q-item-section>
                                    <q-item-section class="p-0 pl-2" side><q-btn @click.prevent="selected_notes=item.new_notes;saveNote(item.id)" round color="success" icon="check" size="sm" dense no-caps /></q-item-section>
                                </template>
                                <q-item-section v-if="$q.screen.lt.sm" side>
                                    <q-btn dense icon="note" @click="item.new_notes=item.notes;item.show_notes = !item.show_notes" flat color="amber" />
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click="showDeletePanel(item.id)" flat color="danger" />
                                </q-item-section>
                            </q-item>
                            <q-card v-if="item.show_notes && $q.screen.lt.sm" class="full-width p-0 m-0 bg-yellow-2 notes-edit">
                                <q-item class="note-header p-2">
                                    <q-item-section class="p-0">
                                        <textarea rows="1" class="bg-yellow-2 p-2 full-width full-height" v-model="item.new_notes" :placeholder="translate('notes')"></textarea>
                                    </q-item-section>
                                    <q-space />
                                    <q-item-section class="p-0 pl-2" side><q-btn @click.prevent="selected_notes=item.new_notes;saveNote(item.id)" round color="success" icon="check" size="sm" dense no-caps /></q-item-section>
                                </q-item>
                            </q-card>
                        </div>
                        <div v-if="!items">
                            <q-item class="m-0 pt-3">{{ translate('No included items') }}</q-item>
                        </div>
                    </b-list-group-item>
                    <infinite-loading slot="append" @infinite="getItems" :identifier="'infiniteLoading-' + item_id  + '-' + infItems" />
                </template>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"
import InfiniteLoading from 'vue-infinite-loading'

import DeleteScheduleAssignation from './DeleteScheduleAssignation'
import IncludeScheduleAssignation from './IncludeScheduleAssignation'

export default {
    name: 'ScheduleAssignations',
    props: ['item_id'],
    components: {
        'include-item-form': IncludeScheduleAssignation
    },
    computed: {
        assign_delete_route: function () {
            return baseUrl + 'logged_schedules'
        },
        assign_post_route: function () {
            return baseUrl + 'logged_schedules'
        },
        assigned_entity_title: function () {
            return this.translate('assigned_' + this.assign_entity)
        },
        placeholder_find_item: function () {
            return 'find_' + this.assign_entity
        },
        get_route_to_include: function () {
            return baseUrl + this.assign_entity
        },
        entity: function () {
            return this.assign_entity.slice(0, -1)
        }
    },
    data: function () {
        return {
            assign_entity: null,
            isHidden: true,
            state: {},
            page: 1,
            items: [],
            infItems: +new Date(),
            selected_notes: null
        }
    },
    methods: {
        updateItemNotes: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.items.findIndex((obj => obj.id === id))
            this.items[objIndex]['notes'] = this.selected_notes
        },
        saveNote: function (id) {
            var notes = this.selected_notes
            
            if (notes) {
                // Axios request only if notes.
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken()
                }
                
                var data = {
                    'notes': notes
                }
                
                var url = this.assign_post_route + '/' + id
                
                axios.patch(url, data, {
                        headers: headers
                    })
                    .then(response => {
                        this.msg = response.data.msg
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'schedule_assignation_notes_' + this.msg + key
                        var translated_message = this.translate(message)

                        if (response.data.status) {
                            this.$toasted.success(translated_message, {
                                duration: this.$toasted_duration
                            })
                            // Update item in the list with the new notes.
                            this.updateItemNotes(id)
                        } 
                        else {
                            this.$toasted.error(translated_message, {
                                duration: this.$toasted_duration
                            })
                        }
                    })
                    .catch(error => {
                        if (error != undefined) {
                            var msg = error.response.data.msg
                            this.msg = msg
                            this.$toasted.error(msg, {
                                duration: 3000
                            })
                        }

                    })
            }
        },
        setAssignEntity: function (entity) {
            this.assign_entity = entity
            eventBus.$emit('update_to_assign')
            this.resetMainList()
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        updateAssignedItems: function () {
            this.resetMainList()
        },
        getItems: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = baseUrl + 'logged_schedules/schedule/' + this.item_id + '?page=' + this.page + '&items_per_page=' + this.$items_per_page
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    var response_items = response.data[this.assign_entity]

                    if (response_items && response_items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.items.push(...response_items.map(entity => {
                            let item = entity
                            item['show_notes'] = false
                            item['new_notes'] = entity.notes
                            return item
                        }))
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel: function (item_id) {
            const panelInstance = this.$showPanel({
                component: DeleteScheduleAssignation,
                props: {
                    item_id: item_id,
                    entity_type: this.entity,
                    post_route: this.assign_delete_route,
                    panel_title: this.translate('delete_assigned_' + this.entity),
                    panel_message: this.translate('deleting_assigned_' + this.entity)
                },
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateAssignedItems()
                        this.$emit('update_list')
                        eventBus.$emit('update_to_assign')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        },
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
.notes-edit textarea {
    max-width: 100%!important;
    border-color: transparent!important;
    color: var(--q-color-dark);

    &:focus,
    &:active {
        background-color: rgba(255, 255, 255, .25)!important;
        border-color: rgba(0, 0, 0, .25)!important;
        outline: medium none!important;
        color: var(--q-color-black);
    }
}
</style>
