<template>
    <div class="fit">
        <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
        <b-tabs borderless>
            <b-tab class="p-0">
                <template slot="title">
                    <i class="mt-1 mb-2 fas fa-close"></i>
                    {{translate('cancel_schedules')}}
                </template>
                <b-list-group class="list-group-accent">
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-danger bg-light font-weight-bold font-small">
                        <q-icon size="1rem" color="red" name="close" />&nbsp;
                        {{ translate('cancel_recurrent_schedule') }}
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item class="p-0">
                            <q-item-section>
                                <q-item-label line="1">{{ translate('canceling_recurrent_schedule_interval') }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    
                        <q-item class="p-0">
                            <q-item-section>
                                <div class="p-0 mb-2 col-6">
                                    <q-card class="mr-2">
                                        <q-input 
                                            borderless 
                                            @focus="$refs.qDateProxy.show()" 
                                            @click="$refs.qDateProxy.show()" 
                                            @input="$refs.qDateProxy.show()" light square color="primary" 
                                            :label="translate('valid_from_date')" dense 
                                            class="full-width m-0 cursor-pointer" 
                                            id="valid_from" 
                                            v-model="planning.date" 
                                            no-error-icon
                                            disable
                                        >
                                        </q-input>
                                    </q-card>
                                </div>
                                <div class="p-0 mb-2 col-6">
                                    <q-card class="ml-2">
                                        <q-input 
                                            borderless 
                                            @focus="$refs.qDateProxy.show()" 
                                            @click="$refs.qDateProxy.show()" 
                                            @input="$refs.qDateProxy.show()" light square color="primary" 
                                            :label="translate('valid_until_date')" dense 
                                            class="full-width m-0 cursor-pointer" 
                                            id="valid_from" 
                                            v-model="end_scheduled_value" 
                                            no-error-icon
                                            :disable="responseScheduleData.length > 0"
                                        >
                                            <template v-slot:prepend>
                                                <q-icon name="event" class="cursor-pointer pointer-events-none">
                                                    <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                                        <q-date
                                                            first-day-of-week="1" 
                                                            mask="YYYY-MM-DD" 
                                                            @input="(val) => onUpdateDateValidUntil(end_scheduled_value)"
                                                            v-model="end_scheduled_value"
                                                            :default-year-month="default_schedule_value"
                                                            :options="optionsFn"/>
                                                    </q-popup-proxy>
                                                </q-icon>
                                            </template>
                                        </q-input>
                                    </q-card>
                                </div>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>

                    <q-separator />
                    
                    <b-list-group-item class="list-group-item-accent-info" v-if="this.end_scheduled_value">
                        <q-item class="p-0" v-if="responseScheduleData.length === 0">
                            <q-item-section>
                                <q-item-label line="1">{{ translate('canceling_recurrent_schedule') }}</q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item class="p-0 full-width" v-if="responseScheduleData.length === 0">
                            <q-item-section side>
                                <q-btn no-caps @click="cancelSchedule" type="submit" color="danger">
                                    <q-icon class="mr-1" size="1rem" name="check" /> {{ translate('yes') }}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn no-caps @click.prevent="closePanel" type="button" color="secondary" class="text-dark">
                                    <q-icon class="mr-1" size="1rem" name="cancel" /> {{ translate('no') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>

                        <q-item class="p-0" v-if="responseScheduleData.length > 0">
                            <q-item-section>
                                <q-item-label line="1"><strong> {{ translate('canceled_schedules') }} </strong></q-item-label>
                            </q-item-section>
                        </q-item>

                        <q-item class="col-12" v-for="(day, i) in responseScheduleData" :key="i+day.date">
                            <div class="row">
                                <q-item-section class="col-2">
                                    <small>{{ translate('date') }}</small>
                                    <strong :class="day.result.status === false ? 'text-red-4' : 'text-green-4'">{{ prettyDate(day.date) }}</strong>
                                </q-item-section>
                                <q-item-section class="col-10 align-content-start align-items-start text-muted" side>
                                    <span> {{ translate('schedule_recurrent_' + day.result.msg) + ' ' + translate('for_date') + ' ' + day.date + '.' }} </span>
                                </q-item-section>
                            </div>
                        </q-item>
                    </b-list-group-item>
                </b-list-group>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    import { eventBus } from '../../../../main'

    export default {
        name: 'CancelSchedule',
        props: ['planning', 'customer_id', 'schedule_id'],
        data() {
            return {
                msg: null,
                dayOfWeek: null,
                end_scheduled_value: null,
                responseScheduleData: []
            }
        },
        mounted: function () {
            this.dayOfWeek = new Date(this.planning.date).getDay();
        },
        computed: {
            default_schedule_value: function () {
                let x = new Date(this.planning.date)
                x.setDate(x.getDate() + 7)
                let month = x.getMonth()+1 < 9 ? '0'+(x.getMonth()+1) : (x.getMonth()+1)

                const date = x.getFullYear() + '/' + month
                return date
            }
        },
        methods: {
            optionsFn (date) {
                const startDate = new Date(this.planning.date)
                const thisDate = new Date(date)

                const dayOfWeek = thisDate.getDay();

                if (thisDate.getTime() <= startDate.getTime()) {
                    return false
                } else {
                    return dayOfWeek === this.dayOfWeek
                }
            },
            onUpdateDateValidUntil: function (newValue) {
                this.end_scheduled_value = newValue

                this.$nextTick(function () {
                    this.$refs.qDateProxy.hide()
                })
            },

            cancelSchedule: function () {
                var content_url = `${baseUrl}schedules/recurrent/${this.schedule_id}/${this.end_scheduled_value}`
                
                var headers = {
                    'Authorization': 'Bearer ' + this.accessToken()
                }

                axios.delete(content_url, {
                        headers: headers
                    })
                    .then(response => {
                        var translated_message = this.translate('cancel_schedule_' + response.data.msg)
                        if (response.data.status) {

                            this.responseScheduleData = [ ...response.data.items ]

                            this.$toasted.success(this.translate('toasted_resource_deleted'), {
                            duration: this.$toasted_duration
                        })

                            eventBus.$emit('update_plannings')
                            eventBus.$emit('delete_schedule', {id: this.schedule_id})
                        } 
                        else {
                            this.$toasted.error(translated_message, {
                                duration: this.$toasted_duration
                            })
                        }
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            },
            closePanel: function () {
                this.$emit('closePanel')
            }
        }
    }
</script>
