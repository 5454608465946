<template>
<div>
    <template>
        <div v-if="!items || items.length == 0">
            <div class="text-muted">{{ translate('no_results') }}</div>
        </div>
        <div v-else>
            <div class="text-muted">{{ translate('results_found') }}: {{ items.length }}</div>
        </div>
        <q-input class="search-input mb-0" @input="isTypingItem = true" v-model="searchItem" id="filternameitem" type="text" :placeholder="placeholder_find_item ? translate(placeholder_find_item) : ''" :dense="true">
            <template v-slot:prepend>
                <q-icon name="search" />
            </template>
        </q-input>
        <div class="items-container" :class="'list_wraper-' + entity_type + '-' + item_id">
            <div v-for="(item,i) in items" :key="'list-item-' + i + '-' + item.id">
                <q-item class="list-item full-width" clickable ripple>
                    <q-item-section>
                        <q-item-label>{{ item.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section v-if="!$q.screen.lt.sm" class="p-0 bg-yellow-2 notes-edit">
                        <textarea rows="1" class="bg-yellow-2 p-2 full-width" v-model="item.new_notes" :placeholder="translate('notes')"></textarea>
                    </q-item-section>
                    <q-item-section side>
                        <q-btn icon="add" @click.prevent="selected_notes=item.new_notes;registerItem(item)" dense flat color="primary" />
                    </q-item-section>
                </q-item>
                <q-item-section v-if="$q.screen.lt.sm" class="p-0 bg-yellow-2 notes-edit">
                    <textarea rows="1" class="bg-yellow-2 p-2 full-width" v-model="item.new_notes" :placeholder="translate('notes')"></textarea>
                </q-item-section>
            </div>
        </div>
        <infinite-loading @infinite="getItems" ref="infiniteLoading" :identifier="'included-items-' + entity_type + '-' + item_id + '-' +infItems" slot="append" />
    </template>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"

import InfiniteLoading from 'vue-infinite-loading'

export default {
    name: 'IncludeScheduleAssignation',
    props: ['post_route', 'get_route_to_include', 'item_id', 'placeholder_find_item', 'entity_type'],
    watch: {
        searchItem: _.debounce(function () {
            this.isTypingItem = false
        }, 200),
        isTypingItem: function (value) {
            if (!value) {
                this.resetMainList()
            }
        }
    },
    components: {
        'infinite-loading': InfiniteLoading
    },
    data: function () {
        return {
            items: [],
            selected_notes: null,
            page: 1,
            state: {},
            infItems: +new Date(),
            isTypingItem: false,
            searchItem: '',
            msg: '',
            item: {
                note: null
            }
        }
    },
    created() {
        eventBus.$on('update_to_assign', () => {
            this.resetMainList()
        })
    },
    methods: {
        resetSelectedNotes: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.items.findIndex((obj => obj.id === id))
            this.items[objIndex]['new_notes'] = null
            this.selected_notes = null
        },
        resetMainList: function () {
            this.page = 1
            this.items = []
            this.infItems++
        },
        post_data: function (item) {
            var data = {}
            var notes = this.selected_notes

            if (this.entity_type === 'exercises') {
                data = {
                    "schedule_id": this.item_id,
                    "exercise_id": item.id,
                }
            }
            else if (this.entity_type === 'trainings') {
                data = {
                    "schedule_id": this.item_id,
                    "training_id": item.id,
                }
            }
            if (notes) {
                data['notes'] = notes
            }
            
            return data
        },
        getItems: function ($state) {
            var route = this.get_route_to_include
            var url = route + '?page=' + this.page + '&items_per_page=' + this.$items_per_page + '&search=' + this.searchItem + '&is_active=1'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.items.push(...response.data.items)
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        registerItem: function (item) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            
            var data = this.post_data(item)
            
            var url = this.post_route
            
            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var entity_type = this.entity_type ? this.entity_type + '_' : ''
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'schedule_assignation_' + entity_type + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        
                        // Emit update events.
                        this.$emit('update_list')
                        eventBus.$emit('update_schedule_status', { schedule_id: this.item_id, schedule_status: 'marked' })
                        eventBus.$emit('update_included_items', {id: this.item_id, route: this.entity_type + 's'})
                        // Reset selected_notes.
                        this.resetSelectedNotes(item.id)
                    } 
                    else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    if (error != undefined) {
                        var msg = error.response.data.msg
                        this.msg = msg
                        this.$toasted.error(msg, {
                            duration: 3000
                        })
                    }

                })
        },
    },
}
</script>

<style lang="scss" scoped>
.items-container {
    max-height: 58.5vh;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
body .search-input {
    width: calc(100% + 30px);
}

.notes-edit textarea {
    max-width: 100%!important;
    border-color: transparent!important;
    color: var(--q-color-dark);

    &:focus,
    &:active {
        background-color: rgba(255, 255, 255, .25)!important;
        border-color: rgba(0, 0, 0, .25)!important;
        outline: medium none!important;
        color: var(--q-color-black);
    }
}
</style>
