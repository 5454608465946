export default [
    {
      title: 'April Fools Day',
      details: 'Everything is funny as long as it is happening to someone else',
      date: '2019-12-01',
      bgcolor: 'orange'
    },
    {
      title: 'Meeting',
      details: 'Time to pitch my idea to the company',
      date: '2019-12-08',
      time: '10:00',
      duration: 120,
      bgcolor: 'red',
      icon: 'fas fa-handshake'
    },
    {
      title: 'Lunch',
      details: 'Company is paying!',
      date: '2019-12-08',
      time: '11:30',
      duration: 90,
      bgcolor: 'teal',
      icon: 'fas fa-hamburger'
    },
    {
      title: 'Visit mom',
      details: 'Always a nice chat with mom',
      date: '2019-12-20',
      time: '13:00',
      duration: 90,
      bgcolor: 'blue-grey',
      icon: 'fas fa-car'
    },
    {
      title: 'Conference',
      details: 'Teaching Javascript 101',
      date: '2019-12-22',
      time: '08:00',
      duration: 240,
      bgcolor: 'blue',
      icon: 'fas fa-chalkboard-teacher'
    },
    {
      title: 'Girlfriend',
      details: 'Meet GF for dinner at Swanky Restaurant',
      date: '2019-12-22',
      time: '14:00',
      duration: 180,
      bgcolor: 'teal',
      icon: 'fas fa-utensils'
    },
    {
      title: 'Fishing',
      details: 'Time for some weekend R&R',
      date: '2019-12-27',
      bgcolor: 'purple',
      icon: 'fas fa-fish',
      days: 2
    },
    {
      title: 'Vacation',
      details: 'Trails and hikes, going camping! Don\'t forget to bring bear spray!',
      date: '2019-12-09',
      bgcolor: 'purple',
      icon: 'fas fa-plane',
      days: 5
    },
    {
      title: 'Sisters Birthday',
      details: 'Buy a nice present',
      date: '2019-12-16',
      bgcolor: 'green',
      time: '10:00',
      duration: 60,
      icon: 'fas fa-birthday-cake'
    },
  ]