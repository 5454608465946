<!-- [+] Show and filter Customers. -->
<template>
<div class="fit choose-customer-container">
    <q-item class="full-width" clickable @click="toggleCustomers()">
        <q-item-section avatar>
            <q-avatar class="cursor-pointer shadow-2">
                <img v-if="selected_customer && selected_customer.image_link" :src="selected_customer.image_link">
                <i v-else class="fas fa-user fa-1x text-muted"></i>
            </q-avatar>
        </q-item-section>
        <q-item-section>
            <span class="text-muted">{{translate('selected_customer')}}</span>
            <h4 class="mb-0 align-self-start cursor-pointer selected-customer-name">{{ selected_customer && selected_customer.name ? selected_customer.name : translate('choose_customer') }}</h4>
        </q-item-section>
        <q-item-section v-if="selected_customer && selected_customer.name" side>
            <q-btn stack flat @click.stop="cleanSelection()" :title="translate('cancel_selection')">
                <i class="fas fa-close"></i>
            </q-btn>
        </q-item-section>
    </q-item>
    <div v-if="showCustomers" class="position-relative p-0 no-focus-style">
        <q-separator />
        <div class="row m-0 align-items-center">
            <div class="col-xs-12 text-right">
                <q-input class="search-input" @input="isTypingCustomer = true" v-model="searchCustomer" id="filternamecustomer" type="text" :placeholder="translate('find_client')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
            </div>
        </div>
        <b-card-body class="card_scroll-customers bg-light m-0 pt-0">
            <div class="list_wrapper-choose-customer">
                <q-item class="full-width list-item" :class="selected_customer && selected_customer.name && customer.name === selected_customer.name ? 'bg-primary' : ''" tag="label" clickable v-ripple v-for="(customer,i) in customers" :key="'customer-' + i + '-' + customer.id">
                    <q-item-section avatar>
                        <q-avatar class="cursor-pointer shadow-2">
                            <img v-if="customer.image_link" :src="customer.image_link">
                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                        </q-avatar>
                    </q-item-section>
                    <q-item-section>
                        <q-item-label>{{ customer.name }}</q-item-label>
                    </q-item-section>
                    <q-item-section side>
                        <q-radio emit-value @input="setSelectedCustomer(customer.id, customer.name, customer.image_link)" size="md" flat color="primary" :id="customer.id" :val="customer" v-model="selected_customer" />
                    </q-item-section>
                </q-item>
            </div>
            <infinite-loading :identifier="infCustomers" slot="append" @infinite="getCustomers" />
        </b-card-body>
    </div>
</div>
</template>
<!-- [-] Show and filter Customers. -->

<script>
import { eventBus } from '../../../../main'

export default {
    name: 'ChooseCustomer',
    watch: {
        searchCustomer: _.debounce(function () {
            this.isTypingCustomer = false
        }, 200),
        isTypingCustomer: function (value) {
            if (!value) {
                this.resetMainList()
            }
        },
    },
    data: function () {
        return {
            showCustomers: false,
            state: {},
            infCustomers: +new Date(),
            customers: [],
            customer_options: [],
            page_customer: 1,
            isTypingCustomer: false,
            searchCustomer: '',
            customer_active: 'active',
            selected_customer: {
                name: ''
            },
            schedule: {},
        }
    },
    methods: {
        cleanSelection: function () {
            this.selected_customer=null
            this.$emit('updateCustomer', {'id': 0, 'name': '', 'image_link': ''})
            eventBus.$emit('updateCustomer', {'id': 0, 'name': '', 'image_link': ''})
        },
        toggleCustomers: function () {
            this.showCustomers = !this.showCustomers
            this.resetMainList()
        },
        hideCustomers: function () {
            this.showCustomers = false
        },
        resetMainList: function () {
            this.page_customer = 1
            this.customers = []
            this.infCustomers++
        },
        setSelectedCustomer: function (customer_id, customer_name, image_link) {
            if (customer_id) {
                this.selected_customer.id = customer_id
                this.selected_customer.name = customer_name
                this.$emit('updateCustomer', {'id': customer_id, 'name': customer_name, 'image_link': image_link})
                eventBus.$emit('updateCustomer', {'id': customer_id, 'name': customer_name, 'image_link': image_link})
            }
            this.showCustomers = false
            return false
        },
        getCustomers: function ($state) {
            var customerUrl = baseUrl +
                'customers?page=' + this.page_customer +
                '&items_per_page=' + this.$items_per_page +
                '&customer_status=' + this.customer_active +
                '&search=' + this.searchCustomer

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var options = []
            this.state = $state
            axios.get(customerUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        options = response.data.items.map(entity => {
                            let array = {label: entity.name, value: entity.id}
                            return array
                        })
                        this.customer_options.push(...options)
                        this.customers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page_customer++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        customerId: function () {
            var id = 0
            if (this.selected_customer && this.selected_customer.id) {
                id = this.selected_customer.id
            }
            return id
        },
    }
}
</script>

<style lang="scss" scoped>
.card_scroll-customers {
    overflow-x: hidden;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    left: 0;
    top: auto;
    z-index: 1000;
    border: 1px solid rgba(0, 0, 0, .1);
    border-top: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }

    &.selected-customer {
        border-color: var(--primary)!important;
    }
}
body .search-input {
    margin-left: 15px !important;
    margin-right: 15px !important;
    max-width: calc(100% - 30px);
}
@media screen and (max-width: 767px) {
    body .search-input {
        margin-top: 10px;
        margin-left: 15px !important;
        margin-right: 0 !important;
    }
    .card_scroll-customers {
        max-height: calc(280px + 3.75rem);
    }
}
</style>
