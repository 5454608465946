<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <i class="fas fa-calendar"></i>
                {{ translate('scheduled_list_title') }}
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="font-weight-bold">
                    <span v-if="event.planning_name" class="text-primary">{{ event.planning_name }}</span>
                </b-list-group-item>
                <b-list-group-item>
                    <span class="text-muted">{{ translate('results_found') }}: {{ schedules.length }}</span>
                    <q-item :dense="$q.screen.lt.sm ? true : null" clickable hoverable v-for="(schedule,i) in schedules" :class="$q.screen.lt.sm ? 'small' : null" class="list-item full-width list-item pl-0" :key="'schedule-' + i + '-' + schedule.id">
                        <q-item-section side class="p-0">
                            <q-chip color="transparent" class="p-0" :dense="$q.screen.lt.sm ? true : null">
                                <q-avatar class="shadow-2 m-0">
                                    <img v-if="schedule.image_link" class="black-and-white" :src="schedule.image_link">
                                    <i v-else class="fas fa-user fa-1x text-muted"></i>
                                </q-avatar>
                            </q-chip>
                        </q-item-section>
                        <q-item-section>
                            <small @click="(schedule.schedule_status === 'attended' || schedule.schedule_status === 'marked') && service_info.log_training === true ? showScheduleAssignationsPanel(schedule.id) : null" v-if="schedule.schedule_status && schedule.schedule_status !== 'pending'" class="text-dark">
                                <q-icon size="1rem" :name="schedule.schedule_status === 'attended' ? 'check' : (schedule.schedule_status === 'marked' ? 'fas fa-check-double' : 'fas fa-exclamation')" :color="statusColor(schedule.schedule_status)" />
                                {{ translate(schedule.schedule_status) }}
                            </small>
                            <strong>{{ schedule.customer_name }}</strong>
                        </q-item-section>
                        <q-item-section side class="pr-0 pl-1">
                            <q-btn round dense size="0.75rem" icon="fas fa-info" color="info" class="details" type="button" @click.stop="showAddSchedulePanel(schedule.customer_id, schedule.customer_name, schedule.image_link)">
                                <q-tooltip :offset="[10, 10]">{{ translate('details') }}</q-tooltip>
                            </q-btn>
                        </q-item-section>
                        <template v-if="schedule.schedule_status === 'pending'">
                            <template>
                                <q-item-section side class="pr-0 pl-1">
                                    <q-btn round dense size="0.75rem" icon="check" color="success" :class="schedule.schedule_status === 'attended' ? 'cursor-default no-pointer-events' : ''" :disabled="statusCheck(schedule.schedule_status, 'pending','attended')" v-if="schedule.schedule_status" type="button" @click="!statusCheck(schedule.schedule_status, 'pending','attended') ? showMarkSchedulePanel(schedule.id, 'attended') : ''">
                                        <q-tooltip :offset="[10, 10]">{{ translate('attended') }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section side class="pr-0 pl-1">
                                    <q-btn round dense size="0.75rem" icon="fas fa-exclamation" color="warning" :class="schedule.schedule_status === 'absence_warned' ? 'cursor-default no-pointer-events' : ''" :disabled="statusCheck(schedule.schedule_status, 'pending','absence_warned')" v-if="schedule.schedule_status" type="button" @click="!statusCheck(schedule.schedule_status, 'pending','absence_warned') ? showMarkSchedulePanel(schedule.id, 'absence_warned') : ''">
                                        <q-tooltip :offset="[10, 10]">{{ translate('absence_warned') }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section side class="pr-0 pl-1">
                                    <q-btn round dense size="0.75rem" icon="fas fa-exclamation" color="danger" :class="schedule.schedule_status === 'absence_penalized' ? 'cursor-default no-pointer-events' : ''" :disabled="statusCheck(schedule.schedule_status, 'pending','absence_penalized')" v-if="schedule.schedule_status" type="button" @click="!statusCheck(schedule.schedule_status, 'pending','absence_penalized') ? showMarkSchedulePanel(schedule.id, 'absence_penalized') : ''">
                                        <q-tooltip :offset="[10, 10]">{{ translate('absence_penalized') }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                            </template>
                            <q-item-section side class="pr-0 pl-1">
                                <q-btn :title="translate('cancel_schedule')" round dense size="0.75rem" icon="delete" color="danger" @click.prevent="showCancelSchedulePanel(schedule)" type="button" />
                            </q-item-section>
                            <q-item-section side class="pr-0 pl-1">
                                <q-btn :title="translate('cancel_recurrent_schedule')" round dense size="0.75rem" icon="delete_sweep" color="danger" @click.prevent="showCancelRecurrentSchedulePanel(schedule)" type="button" />
                            </q-item-section>
                        </template>
                    </q-item>
                </b-list-group-item>
                <b-list-group-item @click.stop.prevent="requestCanceledSchedules" class="font-weight-bold small cursor-pointer">
                    <q-icon class="mr-1" :class="isHidden ? 'text-dark' : 'text-primary'" flat dense size="0.85rem" name="fas fa-calendar-times" />
                    <span class="text-uppercase">{{ translate('canceled_schedules') }}</span>
                    <div class="mt-1" v-if="!isHidden && no_history_message">{{ no_history_message }}</div>
                </b-list-group-item>
                <b-list-group-item class="pt-0">
                    <div class="list_wrapper-canceled_schedules" v-if="!isHidden">
                        <div v-for="(canceled,i) in canceled_schedules" :key="'canceled-schedule-' + i + '-' + canceled.id" class="border mb-1 mt-1">
                            <q-expansion-item class="bg-light" group="canceled_schedules" icon="fas fa-calendar-times text-danger" dense @show="getCanceledScheduleInfo(canceled.id)" :duration="300">
                                <template v-slot:header>
                                    <q-item-section>
                                        <q-chip class="pl-0 pr-0" v-if="!$q.screen.lt.sm" color="transparent">
                                            <small class="mr-4">{{ prettyDate(canceled.date) }}</small>
                                            <q-avatar v-if="canceled.customer_profile_image_link">
                                                <img :src="canceled.customer_profile_image_link">
                                            </q-avatar>
                                            <i v-else class="fas fa-user text-muted"></i>
                                            {{ canceled.customer_name }}
                                        </q-chip>
                                        <template v-else>
                                            <small class="mr-4">{{ prettyDate(canceled.date) }}</small>
                                            <div>
                                                <q-avatar size="28x" v-if="canceled.customer_profile_image_link">
                                                    <img :src="canceled.customer_profile_image_link">
                                                </q-avatar>
                                                <i v-else class="fas fa-user text-muted"></i>
                                                {{ canceled.customer_name }}
                                            </div>
                                        </template>
                                    </q-item-section>
                                </template>
                                <q-card v-if="visible_canceled_info.includes(canceled.id) && canceled.info">
                                    <q-card-section class="p-0 pt-2 pb-2">
                                        <div class="row m-0 align-items-center">
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <small class="text-muted">{{ translate('canceled_at') }}</small><br />
                                                {{ prettyDate(canceled.info.canceled_at) }}
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <small class="text-muted">{{ translate('canceled_by') }}</small><br />
                                                <q-chip color="light" class="pl-0">
                                                    <q-avatar v-if="canceled.info.canceled_by_image_link">
                                                        <img :src="canceled.info.canceled_by_image_link">
                                                    </q-avatar>
                                                    <i v-else class="fas fa-user text-muted"></i>
                                                    {{ canceled.info.canceled_by }}
                                                </q-chip>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <small class="text-muted">{{ translate('schedule_created_at') }}</small><br />
                                                {{ prettyDate(canceled.info.schedule_created_at) }}
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <small class="text-muted">{{ translate('schedule_created_by') }}</small><br />
                                                <q-chip color="light" class="pl-0">
                                                    <q-avatar v-if="canceled.info.schedule_created_by_image_link">
                                                        <img :src="canceled.info.schedule_created_by_image_link">
                                                    </q-avatar>
                                                    <i v-else class="fas fa-user text-muted"></i>
                                                    {{ canceled.info.schedule_created_by }}
                                                </q-chip>
                                            </div>
                                            <div class="col-12 col-sm-6 col-md-3">
                                                <small class="text-muted">{{ translate('schedule_date') }}</small><br />
                                                {{ prettyDate(canceled.info.schedule_date) }}
                                            </div>
                                        </div>
                                    </q-card-section>
                                </q-card>
                            </q-expansion-item>
                        </div>
                        <infinite-loading v-if="!isHidden" ref="InfiniteLoading" :identifier="'infLoading-canceled_schedules-' + infCanceledSchedules" slot="append" @infinite="getCanceledSchedules" />
                    </div>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import * as jwt_decode from 'jwt-decode'
import {
    eventBus
} from '../../../../main'
import MarkSchedule from '../forms/MarkSchedule'
import ScheduleAssignations from '../forms/ScheduleAssignations'
import AddScheduleWorker from '../forms/AddScheduleWorker'
import CancelRecurrentSchedule from '../forms/CancelRecurrentSchedule'
import CancelSchedule from '../forms/CancelSchedule'
import {
    QSlideTransition
} from 'quasar'

export default {
    name: 'ScheduledList',
    props: ['event', 'customer_id', 'customer_name', 'location', 'service'],
    data: function () {
        return {
            no_history_message: '',

            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
            
            isHidden: true,
            visible_canceled_info: [],
            schedules: [],
            canceled_schedules: [],
            page_canceled_schedules: 1,
            infCanceledSchedules: +new Date(),
            planning: null,
            state: {},
            status: false,
            service_info: {}
        }
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        this.getPlanning();
        eventBus.$on('update_schedule_status', (item) => {
            this.updateScheduleStatus(item)
        })
        eventBus.$on('reset_canceled_schedules', () => {
            this.resetCanceledSchedulesList()
        })
        eventBus.$on('delete_schedule', (id) => {
            this.deleteScheduleFromList(id)
        })
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    created: function () {
        this.getSchedules()
        this.getServiceInfo()
        this.getElementsWidthHeight()
     },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        deleteScheduleFromList: function (id) {
            if (id) {
                // Find index of specific object using findIndex method,
                var objIndex = this.schedules.findIndex((obj => obj.id === id))
                // and remove the element from the schedules array.
                this.schedules.splice(objIndex, 1)
            }
        },
        requestCanceledSchedules: function () {
            if (this.isHidden === true) {
                this.isHidden = false
                this.resetCanceledSchedulesList()
            } else {
                this.isHidden = true
                this.canceled_schedules = []
                this.no_history_message = ''
            }
        },
        getCanceledScheduleInfo: function (id) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(baseUrl + 'canceled_schedules/' + id, {
                    headers: headers
                })
                .then(response => {
                    this.canceled_schedules.map(entity => {
                        let array = entity
                        if (entity.id === id) {
                            array['info'] = response.data.item
                            array['info']['canceled_by_image_link'] = response.data.item.canceled_by_image_link ? baseUrl + response.data.item.canceled_by_image_link : null
                            array['info']['schedule_created_by_image_link'] = response.data.item.schedule_created_by_image_link ? baseUrl + response.data.item.schedule_created_by_image_link : null
                            this.visible_canceled_info.push(id)
                            return array
                        }
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        updateScheduleStatus: function (item) {
            if (item.schedule_id !== null) {
                var objIndex = this.schedules.findIndex((obj => obj.id === item.schedule_id))
                if (this.schedules[objIndex]) {
                    this.schedules[objIndex]['schedule_status'] = item.schedule_status
                }
            }
        },
        getServiceInfo: function () {
            var url = baseUrl +
                'services/' + this.service.id

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.service_info = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showAddSchedulePanel: function (customer_id, customer_name, image_link) {
            this.event.customer_image_link = image_link
            this.event.is_scheduled = true
            const panelInstance = this.$showPanel({
                component: AddScheduleWorker,
                props: {
                    event: this.event,
                    customer_id: customer_id,
                    customer_name: customer_name,
                    location: this.location,
                    service: this.service

                }
            })
            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.closePanel()
                }
            })
        },
        closePanel: function () {
            this.$emit('closePanel')
        },
        showScheduleAssignationsPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: ScheduleAssignations,
                props: {
                    item_id: id
                }
            })
        },
        statusColor: function (status) {
            if (status === 'absence_warned') {
                return 'yellow'
            }
            if (status === 'absence_penalized') {
                return 'red'
            }
            if (status === 'marked') {
                return 'info'
            }
            return 'green'
        },
        statusCheck: function (schedule_status, default_status, status) {
            return schedule_status !== default_status && schedule_status !== status
        },
        showMarkSchedulePanel: function (id, status) {
            const panelInstance = this.$showPanel({
                component: MarkSchedule,
                props: {
                    schedule_id: id,
                    schedule_status: status,
                }
            })
        },
        workerId: function () {
            this.token = localStorage.getItem('access_token')
            var decoded = jwt_decode(this.token)
            var id = 0
            if (decoded.identity.worker_id) {
                id = decoded.identity.worker_id
            }
            return id
        },
        getSchedules: function () {
            var url = baseUrl + 'schedules?planning_id=' + this.event.planning_id + '&date=' + this.event.date

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.schedules = response.data.items.map(entity => {
                        let items = entity
                        items['providers_list'] = response.data.providers_list
                        items['log_training'] = response.data.log_training
                        items['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                        return items
                    })
                })
                .catch(function (error) {
                    // console.log(error)
                })

        },
        resetCanceledSchedulesList: function () {
            this.page_canceled_schedules = 1
            this.canceled_schedules = []
            this.infCanceledSchedules++
        },
        getCanceledSchedules: function ($state) {
            var url = baseUrl + 'canceled_schedules/planning?planning_id=' + this.event.planning_id + '&date=' + this.event.date + '&page=' + this.page_canceled_schedules + '&items_per_page=' + this.$items_per_page

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.canceled_schedules.push(...response.data.items.map(entity => {
                            let array = entity
                            array['customer_profile_image_link'] = entity.customer_profile_image_link ? baseUrl + entity.customer_profile_image_link : null
                            return array
                        }))
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                    if (response.status === false || response.data.items.length === 0) {
                        this.no_history_message = this.translate('canceled_schedules_no_canceled_schedules')
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },

        getPlanning: function () {
            var url = baseUrl +
                'plannings/' + this.event.planning_id

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.planning = response.data.item
                    this.getTimeLimitInfo(response.data.item.time_limit_id)
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },

        showCancelSchedulePanel: function (schedule) {
            // show cancel panel
            const panelInstance = this.$showPanel({
                component: CancelSchedule,
                props: {
                    planning: this.event,
                    schedule_id: schedule.id
                }
            })

            panelInstance.promise
                .then(response => {
                    if (response && response.status) {
                        this.status = true
                    }
                    this.closePanel()
                })
            .catch(function (error) {
                // console.log(error)
            })
        },

        showCancelRecurrentSchedulePanel: function (schedule) {
            const panelInstance = this.$showPanel({
                component: CancelRecurrentSchedule,
                props: {
                    planning: this.event,
                    schedule_id: schedule.id
                }
            })

            panelInstance.promise
                .then(response => {
                    if (response && response.status) {
                        this.status = true
                    }
                    this.closePanel()
                })
        },
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
