<template>
<div class="animated fadeIn">
    <calendar-worker v-if="showScheduleWorker"></calendar-worker>
    <calendar-customer v-if="showScheduleCustomer"></calendar-customer>
</div>
</template>

<script>
import SchedulesCalendarWorker from './CalendarWorker';
import ScheduleCustomer from './CalendarCustomer';

export default {
    name: 'Schedules',
    components: {
        'calendar-worker': SchedulesCalendarWorker,
        'calendar-customer': ScheduleCustomer
    },
    data: function () {
        return {
            enableCard: false,
        }
    },
    computed: {
        showScheduleWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
        showScheduleCustomer: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return !this.enableCard;
            }
        }
    },
}
</script>
