<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1">
                    <span class="mr-1">
                        <i class="fas fa-layer-group"></i>
                    </span>
                    {{ translate('panel_title_overlap') }}
                </div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="p-3" :class="!$q.screen.lt.sm ? 'p-4' : ''" v-if="data">
                    <div class="mb-3">
                        <!-- {{ translate('overlapped_events_detailed_info').replace('%msg%', translate('overlapped_msg_' + data.msg)).replace('%event%', translate('overlapped_event_' + data.event_type)) }} -->
                        {{ translate('overlapped_events_detailed_info').replace('%msg%', translate('overlapped_msg_' + data.msg)).replace('%event%', '') }}
                    </div>
                    <div class="mb-3">
                        <span class="mr-3" v-if="data.event_type">
                            <q-icon class="text-info mr-3" size="1.5rem" name="fas fa-calendar-check" />
                            <small class="text-muted" v-if="data.event_id">#{{ data.event_id }}</small>
                            {{ translate('overlapped_event_' + data.event_type) }} 
                            <strong v-if="data.event_name"> {{ data.event_name }}</strong>
                        </span>
                    </div>
                    <div class="mb-3">
                        <span class="mr-3" v-if="data.service_id">
                            <q-icon class="text-info mr-3" size="1.5rem" name="fas fa-swimmer" />
                            <small class="text-muted">#{{ data.service_id }}</small>
                            {{ data.service_name }}
                        </span>
                        <span v-if="data.location_name">
                            <q-icon class="text-info mr-3" size="1.5rem" name="fas fa-map-marker-alt" />
                            
                            <small v-if="$appSettings.currentUserType() === 'worker' && data.location_id" class="text-muted">#{{ data.location_id }}</small>
                            {{ data.location_name }}
                        </span>
                    </div>
                    <div class="mb-3">
                        <span v-if="data.start_date" class="mr-3">
                            <q-icon class="text-info mr-3" size="1.5rem" name="fas fa-calendar-plus" />
                            {{ data.start_date }}
                        </span> 
                        <span v-if="data.end_date">
                            <q-icon class="text-info mr-3" size="1.5rem" name="fas fa-calendar-minus" />
                            {{ data.end_date }}
                        </span>
                    </div>
                    <div class="mb-3">
                        <span v-if="data.start_time" class="mr-3">
                            <q-icon class="text-info mr-3" size="1.5rem" name="fas fa-hourglass-start" />
                            {{ data.start_time }}
                        </span> 
                        <span v-if="data.end_time">
                            <q-icon class="text-info mr-3" size="1.5rem" name="fas fa-hourglass-end" />
                            {{ data.end_time }}
                        </span>
                    </div>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side></q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('close') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import { eventBus } from '../../main'

export default {
    name: 'OverlapDetails',
    props: ['data'],
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
        }
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
