<template>
<div class="fit">
    <q-btn no-caps class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="event" />
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="fas fa-clock" />&nbsp;
                    {{translate('schedule_status')}}
                </b-list-group-item>
                <template v-if="!attended">
                    <b-list-group-item class="list-group-item-accent-info">
                        <p>
                            {{ translate('change_schedule_status_to') }}:
                            <br />
                            <strong>{{ translate(schedule_status) }}</strong>
                        </p>
                    </b-list-group-item>
                    <q-separator />
                    <b-list-group-item class="list-group-item-accent-info">
                        <q-item class="p-0 full-width">
                            <q-item-section side>
                                <q-btn no-caps type="button" color="green" @click="markSchedule" :title="translate('change')">
                                    <q-icon class="mr-1" size="1rem" name="check" /> {{translate('change')}}
                                </q-btn>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side>
                                <q-btn no-caps class="text-dark" @click.prevent="closePanel" type="button" color="secondary" :title="translate('cancel')">
                                    <q-icon class="mr-1" size="1rem" name="cancel" /> {{translate('cancel')}}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </b-list-group-item>
                </template>
                <template v-if="attended && log_training === true">
                    <b-list-group-item class="list-group-item-accent-info">
                        {{ $log(log_training) }}
                        <template v-if="log_training === true">
                            <p>{{ translate('click_on_attended_to_associate_trainings_or_exercises') }}</p>
                            <q-btn no-caps type="button" color="success" @click="log_training === true ? showScheduleAssignationsPanel(schedule_id) : null">
                                <q-icon class="mr-1" size="1rem" name="check" /> {{ translate('attended') }}
                            </q-btn>
                        </template>
                    </b-list-group-item>
                </template>
                <!-- <b-list-group-item v-if="attended && !log_training" class="list-group-item-accent-info">
                    <span><q-icon color="green" size="1rem" name="check" /> {{ translate('attended') }}</span>
                </b-list-group-item> -->
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from '../../../../main'
import ScheduleAssignations from './ScheduleAssignations'

export default {
    name: 'MarkSchedule',
    props: ['schedule_id', 'schedule_status', 'location', 'service', 'log_training'],
    data: function () {
        return {
            attended: false,
        }
    },
    methods: {
        showScheduleAssignationsPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: ScheduleAssignations,
                props: {
                    item_id: id
                }
            })
        },
        markSchedule: function () {
            var schedule_id = this.schedule_id,
                schedule_status = this.schedule_status,
                url = baseUrl + 'schedules/' + schedule_id + '/status/' + this.schedule_status,
                headers = {
                    'Authorization': 'Bearer ' + this.accessToken(),
                },
                data = {
                    'schedule_status': schedule_status
                }
            
            axios.patch(url, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'changed_schedule_status_' + response.data.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        if (this.schedule_status === 'attended') {
                            this.attended = true
                        }
                        // Emit update event.
                        eventBus.$emit('update_schedule_status', { schedule_id: schedule_id, schedule_status: schedule_status })
                        if (this.attended && !this.log_training) {
                            this.closePanel()
                        }
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>
