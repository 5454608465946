<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon class="mr-1" name="event" />{{ translate(schedule_title) }} <span v-if="event.planning_name" class="text-primary">{{ event.planning_name }}</span>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent="get_schedule_option()">
                    <b-list-group-item class="p-0" :class="in_time === false ? 'list-group-item-accent-danger' : 'list-group-item-accent-info'">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-icon name="group" size="2rem"></q-icon>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('schedules') }}</small>
                                        <strong :class="event.scheduled_customers === event.max_clients ? 'text-red' : ''">{{ event.scheduled_customers }}/{{ event.max_clients }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div class="col-md p-0"></div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-icon name="event" size="2rem"></q-icon>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('date') }}</small>
                                        <strong :class="in_time === false ? 'text-red-4' : ''">{{ prettyDate(event.date) }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <i class="fas fa-clock fa-2x"></i>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('hour') }}</small>
                                        <strong>{{ event.start_time }} - {{ event.end_time }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <i class="fas fa-swimmer fa-2x"></i>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('service') }}</small>
                                        <strong>{{ service.name }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <i class="fas fa-map-marker-alt fa-2x"></i>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('location') }}</small>
                                        <strong>{{ location.name }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>

                        <div class="pb-3" v-if="!event.is_scheduled && provider_options.length && service_info.providers_list && service_info.providers_list === 'selectable'">
                            <!-- Selectable Providers -->
                            <q-select class="m-0" flat square outlined color="info" :label="translate('select_provider')" :options="provider_options" v-model="selected_provider">
                                <template v-slot:prepend>
                                    <q-avatar class="shadow-2">
                                        <img v-if="selected_provider && selected_provider.image_link" :src="selected_provider.image_link">
                                        <i v-else class="fas fa-user fa-1x text-muted"></i>
                                    </q-avatar>
                                </template>
                                <template v-slot:option="scope">
                                    <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
                                        <q-item-section side>
                                            <q-avatar class="shadow-2">
                                                <img v-if="scope.opt.image_link" :src="scope.opt.image_link">
                                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                                            </q-avatar>
                                        </q-item-section>
                                        <q-item-section>
                                            <q-item-label v-html="scope.opt.label"></q-item-label>
                                        </q-item-section>
                                    </q-item>
                                </template>
                            </q-select>
                        </div>

                        <template v-if="provider_options && provider_options.length && service_info.providers_list && service_info.providers_list === 'viewable'">
                            <!-- Viewable Providers -->
                            <div class="row" :class="$q.screen.lt.sm ? 'm-0 pl-2' : ''">
                                <q-item class="full-width pl-2">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" :class="!$q.screen.lt.sm ? 'pr-0' : ''" side>
                                        <i class="fas fa-user fa-2x"></i>
                                    </q-item-section>
                                    <q-item-section class="col-11" :class="!$q.screen.lt.sm ? 'p-0' : ''">
                                        <q-btn-dropdown color="dark" :label="translate('view_providers')" class="text-capitalize">
                                            <q-item v-for="provider in provider_options" :key="provider.value">
                                                <q-item-section side>
                                                    <q-avatar class="shadow-2">
                                                        <img v-if="provider.image_link" :src="provider.image_link">
                                                        <i v-else class="fas fa-user fa-1x text-muted"></i>
                                                    </q-avatar>
                                                </q-item-section>
                                                <q-item-section>{{ provider.label }}</q-item-section>
                                            </q-item>
                                        </q-btn-dropdown>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </template>

                        <div v-if="event.is_scheduled && selected_provider && selected_provider.label && service_info.providers_list === 'selectable'">
                            <!-- Selected Provider -->
                            <div class="row" :class="$q.screen.lt.sm ? 'm-0 pl-2' : ''">
                                <q-item class="full-width pl-2">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" :class="!$q.screen.lt.sm ? 'pr-0' : ''" side>
                                        <q-avatar class="shadow-2">
                                            <img v-if="selected_provider.image_link" :src="selected_provider.image_link">
                                            <i v-else class="fas fa-walking fa-1x text-muted"></i>
                                        </q-avatar>
                                    </q-item-section>
                                    <q-item-section class="col-11" :class="!$q.screen.lt.sm ? 'p-0' : ''">
                                        <small>{{ translate('selected_provider') }}</small>
                                        <strong class="text-green-9">{{ selected_provider.label }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>
                        <div class="pl-3 pb-2" v-if="event.is_scheduled && schedule">
                            <h3 class="font-weight-bold mb-0 mt-2 text-uppercase small">{{ translate('schedule_status') }}</h3>
                            <q-item v-if="schedule.status" class="p-0 full-width">
                                <q-item-section side v-if="schedule.status === 'pending'">
                                    <q-btn 
                                        no-caps class="cursor-default text-dark" 
                                        v-if="schedule.status" type="button" 
                                        :round="$q.screen.lt.sm" :dense="$q.screen.lt.sm" color="white">
                                        <q-icon class="mr-1" size="1rem" name="check" /> <span v-if="!$q.screen.lt.sm">{{ translate(schedule.status)  }}</span>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section side v-if="schedule.status === 'absence_warned'">
                                    <q-btn 
                                        no-caps class="cursor-default text-warning" 
                                        v-if="schedule.status" type="button" 
                                        :round="$q.screen.lt.sm" :dense="$q.screen.lt.sm" color="white">
                                        <q-icon class="mr-1" size="1rem" name="fas fa-exclamation" /> <span v-if="!$q.screen.lt.sm">{{ translate('absence_warned') }}</span>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section side v-if="schedule.status === 'absence_penalized'">
                                    <q-btn 
                                        no-caps class="cursor-default text-danger" 
                                        v-if="schedule.status" type="button" 
                                        :round="$q.screen.lt.sm" :dense="$q.screen.lt.sm" color="white">
                                        <q-icon class="mr-1" size="1rem" name="fas fa-exclamation" /> <span v-if="!$q.screen.lt.sm">{{ translate('absence_penalized') }}</span>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section side v-if="schedule.status === 'marked'">
                                    <q-btn 
                                        no-caps class="cursor-default text-info" 
                                        v-if="schedule.status" type="button" 
                                        :round="$q.screen.lt.sm" :dense="$q.screen.lt.sm" color="white">
                                        <q-icon class="mr-1" size="1rem" name="fas fa-check-double" /> <span v-if="!$q.screen.lt.sm">{{ translate(schedule.status) }}</span>
                                    </q-btn>
                                </q-item-section>
                                <q-item-section side v-if="schedule.status === 'attended'">
                                    <q-btn 
                                        no-caps class="cursor-default text-success" 
                                        v-if="schedule.status" type="button" 
                                        :round="$q.screen.lt.sm" :dense="$q.screen.lt.sm" color="white">
                                        <q-icon class="mr-1" size="1rem" name="fas fa-check" /> <span v-if="!$q.screen.lt.sm">{{ translate(schedule.status) }}</span>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </div>
                    </b-list-group-item>
                    <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                        <q-item class="p-3">
                            <q-item-section side :class="$q.screen.lt.sm ? 'p-0' : ''">
                                <template v-if="!event.is_scheduled">
                                    <q-btn 
                                        type="submit" 
                                        no-caps 
                                        color="info" 
                                        :disable="selected_option_schedule.value === 'recurring_schedule' && finalDays.length === 0 || responseScheduleData.length > 0">
                                        <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('add_schedule') }}
                                    </q-btn>
                                </template>
                                <template v-if="event.is_scheduled && schedule && schedule.status === 'pending'">
                                    <q-btn @click.prevent="showCancelSchedulePanel(event)" type="button" no-caps color="danger">
                                        <q-icon size="1rem" name="delete" class="mr-1" /> {{ translate('cancel_schedule') }}
                                    </q-btn>
                                </template>
                            </q-item-section>
                            <q-item-section side :class="$q.screen.lt.sm ? 'p-0' : ''">
                                <template v-if="event.is_scheduled && schedule && schedule.status === 'pending'">
                                    <q-btn @click.prevent="showCancelRecurrentSchedulePanel(event)" type="button" no-caps color="danger">
                                        <q-icon size="1rem" name="delete" class="mr-1" /> {{ translate('cancel_recurrent_schedule') }}
                                    </q-btn>
                                </template>
                            </q-item-section>
                            <q-item-section></q-item-section>
                            <q-item-section side :class="$q.screen.lt.sm ? 'p-0' : ''">
                                <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                    <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('close') }}
                                </q-btn>
                            </q-item-section>
                        </q-item>
                    </div>
                    <q-select
                        v-if="!event.is_scheduled"
                        v-model="selected_option_schedule"
                        float-label="Is Quasar Awesome?"
                        radio
                        :disable="responseScheduleData.length > 0"
                        :options="scheduleOptions"
                        :display-value="selected_option_schedule.label"
                    />
                    <div v-if="selected_option_schedule.value === 'recurring_schedule'" class="row px-3 pb-3 m-0">
                        <div class="p-0 mb-2 col-6">
                            <q-card class="mr-2">
                                <q-input 
                                    borderless 
                                    @focus="$refs.qDateProxy.show()" 
                                    @click="$refs.qDateProxy.show()" 
                                    @input="$refs.qDateProxy.show()" light square color="primary" 
                                    :label="translate('valid_from_date')" dense 
                                    class="full-width m-0 cursor-pointer" 
                                    id="valid_from" 
                                    v-model="event.date" 
                                    no-error-icon
                                    disable
                                >
                                </q-input>
                            </q-card>
                        </div>
                        <div class="p-0 mb-2 col-6">
                            <q-card class="ml-2">
                                <q-input 
                                    borderless 
                                    @focus="$refs.qDateProxy.show()" 
                                    @click="$refs.qDateProxy.show()" 
                                    @input="$refs.qDateProxy.show()" light square color="primary" 
                                    :label="translate('valid_until_date')" dense 
                                    class="full-width m-0 cursor-pointer" 
                                    id="valid_from" 
                                    v-model="end_schedule_value" 
                                    no-error-icon
                                >
                                    <template v-slot:prepend>
                                        <q-icon name="event" class="cursor-pointer pointer-events-none">
                                            <q-popup-proxy ref="qDateProxy" transition-show="scale" transition-hide="scale">
                                                <q-date
                                                    :disable="responseScheduleData.length > 0"
                                                    first-day-of-week="1" 
                                                    mask="YYYY-MM-DD" 
                                                    @input="(val) => onUpdateDateValidUntil(end_schedule_value)"
                                                    v-model="end_schedule_value"
                                                    :default-year-month="default_schedule_value"
                                                    :options="optionsFn"/>
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </q-card>
                        </div>
                        
                        <div class="row">
                            <h3 class="font-weight-bold mb-0 mt-2 text-uppercase small m-3">
                                {{
                                    finalDays.length > 0 && responseScheduleData.length === 0 ? 
                                    translate('scheduled_days') : responseScheduleData.length > 0 ?
                                    translate('schedules_omitted') : null
                                }}
                            </h3>
                            <div class="row px-4 m-0" v-if="responseScheduleData.length === 0">
                                <q-item class="col-3" v-for="(day, i) in finalDays" :key="i" >
                                    <q-item-section class="align-content-center align-items-center col-3 text-muted" side>
                                        <q-icon name="event" size="2rem"></q-icon>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('date') }}</small>
                                        <strong class="text-green-4">{{ prettyDate(day) }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div class="row px-4 m-0">
                                <q-item class="col-12" v-for="(day, i) in responseScheduleData" :key="i+day.date">
                                    <div class="row">
                                        <q-item-section class="col-2">
                                            <small>{{ translate('date') }}</small>
                                            <strong :class="day.result.status === false ? 'text-red-4' : 'text-green-4'">{{ prettyDate(day.date) }}</strong>
                                        </q-item-section>
                                        <q-item-section class="col-10 align-content-start align-items-start text-muted" side>
                                            <span>{{day.result.msg}}</span>
                                        </q-item-section>
                                    </div>
                                </q-item>
                            </div>
                        </div>
                    </div>
                </b-form>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import * as jwt_decode from 'jwt-decode'
import { eventBus } from '../../../../main'
import CancelSchedule from './CancelSchedule'
import CancelRecurrentSchedule from './CancelRecurrentSchedule'
import OverlapDetails from '../../../template-parts/OverlapDetails'

export default {
    name: 'AddSchedule',
    props: ['event', 'location', 'service'],
    data: function () {
        return {
            scheduleOptions: [
                { label: 'Rezervare Simplă', value: 'simple_schedule' },
                { label: 'Rezervare Recurentă', value: 'recurring_schedule' }
            ],
            selected_option_schedule: { label:'Rezervare Simplă', value: 'simple_schedule' },
            end_schedule_value: null,

            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            in_time: false,
            time_limit: null,
            service_info: {
                providers_list: null,
            },
            provider_options: [
                {
                    'value': 0,
                    'label': '-'
                }
            ],
            selected_provider: {},
            planning: {},
            schedule: {},
            schedule_title_text: '',
            schedule_title_default: 'add_schedule_title',
            schedule_title_restricted: 'schedule_title',
            status: null,
            
            dayOfWeek: 0,
            scheduleDays: [],
            finalDays: [],
            responseScheduleData: []
        }
    },
    computed: {
        show_schedule_buttons: function () {
            // Show schedule action buttons if in time.
            // In order to add or cancel a schedule, the event date must be later than 1 day and the hour of the current date must be earlier than 21 o'clock.
            
            if (!this.in_time) {
                return false
            }

            return true
        },
        schedule_title: {
            get() {
                var in_time = this.in_time
                if (this.event.is_scheduled === true || this.event.scheduled_customers >= this.event.max_clients || !in_time) {
                    this.schedule_title_text = this.schedule_title_restricted
                } else {
                    this.schedule_title_text = this.schedule_title_default
                }
                
                return this.schedule_title_text
            },
            set(value) {
                this.schedule_title_text = value
            }
        },
        default_schedule_value: function () {
            let x = new Date(this.event.date)
            x.setDate(x.getDate() + 7)
            let month = x.getMonth()+1 < 9 ? '0'+(x.getMonth()+1) : (x.getMonth()+1)

            const date = x.getFullYear() + '/' + month
            return date
        }
    },
    created: function () {
        this.getServiceInfo()
        this.getPlanning()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        this.dayOfWeek = new Date(this.event.date).getDay();
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        optionsFn (date) {
            const startDate = new Date(this.event.date)
            const thisDate = new Date(date)

            const dayOfWeek = thisDate.getDay();

            if (thisDate.getTime() <= startDate.getTime()) {
                return false
            } else {
                return dayOfWeek === this.dayOfWeek
            }
        },
        showOverlapPanel: function (data) {
            const panelInstance = this.$showPanel({
                component: OverlapDetails,
                props: {
                    data: data
                }
            })
        },
        onUpdateDateValidUntil: function (newValue) {
            this.end_schedule_value = newValue

            const startDate = new Date(this.event.date)
            const endDate = new Date(newValue)

            const nextDate = new Date(this.event.date)
            nextDate.setDate(nextDate.getDate() + 7)

            const allDays = [startDate.toJSON().slice(0, 10)]

            while (nextDate.getTime() < endDate.getTime()) {
                allDays.push(nextDate.toJSON().slice(0, 10))
                nextDate.setDate(nextDate.getDate() + 7)
            }

            allDays.push(endDate.toJSON().slice(0, 10))

            this.finalDays = [...allDays]

            this.$nextTick(function () {
                this.$refs.qDateProxy.hide()
            })
        },
        statusColor: function (status) {
            if (status === 'absence_warned') {
                return 'yellow'
            }
            if (status === 'absence_penalized') {
                return 'red'
            }
            if (status === 'marked') {
                return 'info'
            }
            return 'green'
        },
        getTimeLimitInfo: function (id) {
            var url = baseUrl +
                'time_limits/' + id

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.time_limit = response.data.item

                    this.getInTime()
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getPlanning: function () {
            var url = baseUrl +
                'plannings/' + this.event.planning_id

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.planning = response.data.item
                    this.getTimeLimitInfo(response.data.item.time_limit_id)
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getServiceInfo: function () {
            var url = baseUrl +
                'services/' + this.service.id

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.service_info = response.data.item

                    this.getServiceProviders()
                    
                    // Get Planning Schedules only if there has been made a schedule.
                    if (this.event.is_scheduled && this.service_info.providers_list) {
                        this.getPlanningSchedules()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getPlanningSchedules: function () {
            var date = this.event.date
            var url = baseUrl +
                'schedules/planning?planning_id=' + this.event.planning_id + '&date=' + date

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.schedule.id = response.data.schedule_id
                    if (this.event.is_scheduled && this.schedule.id) {
                        this.getSchedule()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getSchedule: function () {
            var start_date = this.event.start_date
            var url = baseUrl +
                'schedules/' + this.schedule.id

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    var schedule = response.data.item
                    this.schedule = schedule
                    this.schedule.selected_worker_image_link = schedule.selected_worker_image_link ? baseUrl + schedule.selected_worker_image_link : null
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getServiceProviders: _.debounce(function () {
            var url = baseUrl +
                'plannings/workers?planning_id=' + this.event.planning_id

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken()
            }
            var providers = []
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    providers = response.data.items.map(entity => {
                        let array = {'value': entity.worker_id, 'label': entity.name, 'image_link': entity.image_link ? baseUrl + entity.image_link : null}
                        return array
                    })
                    this.provider_options = providers
                    this.selected_provider = this.schedule.selected_worker_name 
                                            ? { value: this.schedule.selected_worker_id, label: this.schedule.selected_worker_name, image_link: this.schedule.selected_worker_image_link } 
                                            : this.provider_options[0]
                })
                .catch(function (error) {
                    // console.log(error)
                })
        }, 200),
        getInTime: _.debounce(function () {
            var event = this.event,
                today = new Date(),
                event_date = new Date(event.date),
                deadline_date = 0, // Event day minus 1 day
                mph = 60, // Minutes per hour
                spm = 60, // Seconds per minute
                time_event = event.start_time,
                times_event = time_event.split(':'),
                time_now = today.getHours() + ':' + today.getMinutes(),
                times_now = time_now.split(':'),
                seconds_event = ((times_event[0]) * mph * spm) + ((times_event[1]) * spm),
                seconds_now = ((times_now[0]) * mph * spm) + ((times_now[1]) * spm)
            
            if (this.time_limit && this.time_limit.limit_type === 'day_before') {
                times_event = this.time_limit.day_before_time.split(':')
                seconds_event = ((times_event[0]) * mph * spm) + ((times_event[1]) * spm),
                deadline_date = 1
            }

            this.in_time = Date.dateDiff('d', event_date, today) > deadline_date || (Date.dateDiff('d', event_date, today) === deadline_date && seconds_now < seconds_event)
        }, 200),
        showCancelSchedulePanel: function (planning) {
            const panelInstance = this.$showPanel({
                component: CancelSchedule,
                props: {
                    planning: planning,
                    schedule_id: this.schedule.id
                }
            })

            panelInstance.promise
                .then(response => {
                    if (response && response.status) {
                        this.status = true
                    }
                    this.closePanel()
                })
        },
        showCancelRecurrentSchedulePanel: function (planning) {
            const panelInstance = this.$showPanel({
                component: CancelRecurrentSchedule,
                props: {
                    planning: planning,
                    schedule_id: this.schedule.id
                }
            })

            panelInstance.promise
                .then(response => {
                    if (response && response.status) {
                        this.status = true
                    }
                    this.closePanel()
                })
        },
        workerId: function () {
            var id = 0
            if (this.selected_provider) {
                id = this.selected_provider.value
            }
			return id
        },
        customerId: function () {
			this.token = localStorage.getItem('access_token')
            var decoded = jwt_decode(this.token)
            var id = 0
            if (decoded.identity.customer_id) {
                id = decoded.identity.customer_id
            }
			return id
        },
        get_schedule_option: function () {
            return (
                this.selected_option_schedule.value === 'simple_schedule' ? 
                    this.registerSchedule() : 
                    this.registerRecurringSchedule()
            )
        },
        registerRecurringSchedule: function () {
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }
            var data = {
                'planning_id': this.event.planning_id,
                'start_date': this.event.date,
                'end_date': this.end_schedule_value,
                'selected_worker_id': this.workerId(),
                'customer_id': this.customerId(),
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'schedules/recurrent', data, {
                    headers: headers
            }).then(response => {

                this.responseScheduleData = [ ...response.data.items ]

                this.responseScheduleData.forEach(item => {
                    var msg = item.result.msg
                    var message = 'add_schedule_' + msg
                    var translated_message = this.translate(message)
                    
                    if (this.service.name) {
                        // Add service name in notification.
                        translated_message = translated_message.replace('{{service_name}}', this.service.name)
                    }
                    if (this.event.date) {
                        // Add date in notification.
                        translated_message = translated_message.replace('{{date}}', this.event.date)
                    }

                    item.result.msg = translated_message

                    eventBus.$emit('update_plannings')

                })

            })
            .catch(error => {
                var msg = error.response.data.msg
                if (msg != undefined) {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                }
            })
        }, 
        registerSchedule: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }
            var data = {
                'planning_id': this.event.planning_id,
                'date': this.event.date,
                'selected_worker_id': this.workerId(),
                'customer_id': this.customerId(),
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'schedules', data, {
                    headers: headers
                })
                .then(response => {
                    var msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_schedule_' + msg + key
                    var translated_message = this.translate(message)
                    
                    if (this.service.name) {
                        // Add service name in notification.
                        translated_message = translated_message.replace('{{service_name}}', this.service.name)
                    }
                    if (this.event.date) {
                        // Add date in notification.
                        translated_message = translated_message.replace('{{date}}', this.event.date)
                    }

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        eventBus.$emit('update_plannings')
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        if (response.data.msg && _.endsWith(response.data.msg, '_overlap')) {
                            // open new panel with overlap details displayed.
                            this.showOverlapPanel(response.data)
                        } else {
                            this.$toasted.error(translated_message, {
                                duration: this.$toasted_duration
                            })
                        }
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        closePanel() {
            this.$emit('closePanel', {
                status: this.status
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
